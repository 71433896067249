import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

function ZoomableImage({ src, imgW, imgH }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",

        // position: "fixed",
        // width: "98%",
        // height: "98%",
        border: "1px solid rgb(0, 0, 0)",
        borderRadius: "20px",
        overflow: "clip",
        // top: "1%",
        // bottom: "1%",
        // left: "1%",
      }}
    >
      <TransformWrapper centerOnInit>
        <TransformComponent
          wrapperStyle={{
            width: "100%",
            // height: "97vh",
            background: "rgb(247 243 243 / 57%)",
            height: "100%",
            cursor: "zoom-in",
          }}
        >
          <img
            style={{ width: "100%", maxHeight: imgH, cursor: "zoom-in" }}
            src={src}
            alt="test"
          />
        </TransformComponent>
      </TransformWrapper>
    </div>
  );
}

export default ZoomableImage;
