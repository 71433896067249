import React, { useEffect, useRef, useState } from "react";
import { IoClose } from "react-icons/io5";
import personIcon from "../../media/personIcon.png";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import { ThreeDots } from "react-loader-spinner";

const CongratulationModal = ({
  showCongratulationModal,
  setCongratulationModal,
  givenQuestions,
  aggregatedResults,
}) => {
  const [sliderIndex, setSliderIndex] = useState(0);
  const [sliderData, setSliderData] = useState([]);
  const [countdownTime, setCountdownTime] = useState("");
  const [topPosition, setTopPosition] = useState("");

  const splideRef = useRef();
  const goToRight = () => {
    setSliderIndex((prev) => prev + 1);
    splideRef.current.splide.go(">");
  };
  const goToLeft = () => {
    setSliderIndex((prev) => prev - 1);
    splideRef.current.splide.go("<");
  };

  const calculateCountdown = () => {
    const now = new Date();
    const midnight = new Date();
    midnight.setHours(24, 0, 0, 0); // set to midnight
    const diff = midnight - now;

    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diff % (1000 * 60)) / 1000);

    setCountdownTime(
      `is out in ${hours} hour${hours !== 1 ? "s" : ""}, ${minutes} minute${
        minutes !== 1 ? "s" : ""
      } and ${seconds} second${seconds !== 1 ? "s" : ""}!`
    );
  };

  useEffect(() => {
    let UserObject = localStorage.getItem("UserGamePlay");
    UserObject = JSON.parse(UserObject);
    const { gameState } = UserObject;

    if (!aggregatedResults) {
      return;
    }

    const questions2 = [];
    givenQuestions.forEach((question, qIndex) => {
      if (aggregatedResults && aggregatedResults.trialStats) {
        aggregatedResults.trialStats[qIndex].forEach((trialStat, tIndex) => {
          questions2.push({
            question: question.question,
            answers: question.answers,
            corr_ans: question.corr_ans,
            MyTry: gameState[qIndex][tIndex],
            questionNumber: qIndex,
            tryNumber: tIndex,
            trial_stats: [trialStat],
          });
        });
      }
    });
    setSliderData(questions2);

    // Calculate the user's top position
    const userScore = UserObject.AvgScorePercentage;
    const scoresList = aggregatedResults.orderedListOfScores;
    const totalScores = scoresList.length;

    console.log("Scores List:", scoresList);
    console.log("User Score:", userScore);
    console.log("Total Scores:", totalScores);

    let rank = totalScores;
    for (let i = 0; i < totalScores; i++) {
      console.log(
        `Comparing userScore (${userScore}) with scoresList[${i}] (${scoresList[i]})`
      );
      if (userScore >= scoresList[i]) {
        rank = i + 1;
      }
    }
    console.log("Rank:", rank);
    console.log("totalScores:", totalScores);

    // Fixing the topPercentage calculation
    const topPercentage = Math.round(
      ((totalScores - rank) / totalScores) * 100 + 1
    );
    console.log("Top Percentage:", topPercentage);
    setTopPosition(topPercentage);

    const interval = setInterval(() => {
      calculateCountdown();
    }, 1000);

    return () => clearInterval(interval); // cleanup interval on unmount
  }, [aggregatedResults, givenQuestions]);

  const calculateTrailPercentage = (totalArray, numberOfTrie) => {
    const totalPeople = totalArray.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);

    if (totalPeople <= 0) {
      return 0;
    }
    if (numberOfTrie < 0) {
      return 0;
    }
    if (numberOfTrie > totalPeople) {
      return 0;
    }
    const percentage = (numberOfTrie / totalPeople) * 100;
    return `${percentage}%`;
  };

  const handleMoved = (splide, newIndex, oldIndex) => {
    setSliderIndex(newIndex);
  };

  return (
    <div className="congratulationModalMain">
      <IoClose
        onClick={() => setCongratulationModal(!showCongratulationModal)}
        className="close-icon"
      />

      {aggregatedResults == null || aggregatedResults?.trialStats == null ? (
        <ThreeDots
          visible={true}
          height="80"
          width="80"
          color="#4fa94d"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      ) : (
        <div className="slider-center">
          {sliderIndex === 0 ? (
            ""
          ) : (
            <GoChevronLeft onClick={() => goToLeft()} className="arrow-left" />
          )}
          {sliderIndex === sliderData.length + 1 ? (
            ""
          ) : (
            <GoChevronRight
              onClick={() => goToRight()}
              className="arrow-right"
            />
          )}

          <Splide
            options={{
              speed: "500",
              arrows: false,
              pagination: false,
            }}
            ref={splideRef}
            onMoved={handleMoved}
          >
            <SplideSlide>
              <div className="congratulationModal">
                <p className="congratulation-modal-text1">Congratulations!</p>
                <p className="congratulation-modal-text">
                  Based on your answers, you are in the TOP
                </p>
                <p className="congratulation-percentage">
                  {topPosition !== "" ? topPosition : ".."}%
                </p>
                <div className="congratulation-bottom-text-mainDiv">
                  <p className="congratulation-bottom-text">
                    Swipe Right To See What Other Players Did
                  </p>
                </div>
              </div>
            </SplideSlide>
            {sliderData.map((question, index) => (
              <SplideSlide key={index}>
                <div>
                  <p className="second-slider-title">What other players did</p>
                  <p className="second-slider-title-bottom-text">
                    Q{question?.questionNumber + 1} - Trial-
                    {question?.tryNumber + 1}
                  </p>
                  <p className="second-slider-quiz-question1">
                    {question?.question}
                  </p>

                  <div className="all-quiz-containerDiv">
                    {question?.answers.map((answer, answerIndex) => (
                      <>
                        {answer === question?.corr_ans ? (
                          <div
                            className="quiz-option-mainDivGreen"
                            key={answerIndex}
                          >
                            <div
                              style={{
                                width: calculateTrailPercentage(
                                  question?.trial_stats[0],
                                  question?.trial_stats[0][answerIndex]
                                ),
                              }}
                              className="percentageDiv-Green"
                            ></div>
                            <div className="quiz-option">
                              {answerIndex === 0 && " A.  "}
                              {answerIndex === 1 && " B.  "}
                              {answerIndex === 2 && " C.  "}
                              {answerIndex === 3 && " D.  "}
                              {answerIndex === 4 && " E.  "}
                              {answerIndex === 5 && " F.  "}
                              {answerIndex === 6 && " G.  "}
                              {answer}
                            </div>
                            {question?.MyTry === answer && (
                              <img src={personIcon} alt="selected questions" />
                            )}

                            <div className="quiz-mark">
                              {question?.trial_stats[0][answerIndex]}
                            </div>
                          </div>
                        ) : (
                          <div
                            className="quiz-option-mainDiv"
                            key={answerIndex}
                          >
                            <div
                              style={{
                                width: calculateTrailPercentage(
                                  question?.trial_stats[0],
                                  question?.trial_stats[0][answerIndex]
                                ),
                              }}
                              className="percentageDiv"
                            ></div>
                            <div className="quiz-option">
                              {answerIndex === 0 && " A.  "}
                              {answerIndex === 1 && " B.  "}
                              {answerIndex === 2 && " C.  "}
                              {answerIndex === 3 && " D.  "}
                              {answerIndex === 4 && " E.  "}
                              {answerIndex === 5 && " F.  "}
                              {answerIndex === 6 && " G.  "}
                              {answer}
                            </div>
                            {question?.MyTry === answer && (
                              <img src={personIcon} alt="selected questions" />
                            )}

                            <div className="quiz-mark">
                              {question?.trial_stats[0][answerIndex]}
                            </div>
                          </div>
                        )}
                      </>
                    ))}
                  </div>
                </div>
              </SplideSlide>
            ))}

            <SplideSlide>
              <div className="congratulationModal">
                <p className="congratulation-modal-text1">
                  Tomorrow’s puzzle ...
                </p>
                <p className="congratulation-countdown">{countdownTime}</p>
              </div>
            </SplideSlide>
          </Splide>

          <div className="sliderMain">
            <div className="sliderPagination">
              {Array(sliderData.length + 2)
                .fill(null)
                .map((item, index) => (
                  <div
                    key={index}
                    className={`${
                      index === sliderIndex ? "greenDot" : "sliderDot"
                    } `}
                  ></div>
                ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CongratulationModal;
