import React, { useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import HomePage from "./components/home/home";
import Play from "./components/play/play";
import Login from "./components/auth/login";
import Register from "./components/auth/register";
import ForgotPassword from "./components/auth/ForgotPassword";
import { AuthProvider } from "./contexts/authContext";
import RedirectToPrivacy from "./components/policies/RedirectToPrivacy"; // Import the RedirectToPrivacy component
import RedirectToTerms from "./components/policies/RedirectToTerms";

function App() {
  const [canAccessPlay, setCanAccessPlay] = useState(false);

  const allowAccessToPlay = () => {
    setCanAccessPlay(true);
  };

  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route
            path="/"
            element={<HomePage allowAccessToPlay={allowAccessToPlay} />}
          />
          <Route
            path="/play"
            element={canAccessPlay ? <Play /> : <Navigate to="/" />}
          />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/privacy" element={<RedirectToPrivacy />} />{" "}
          <Route path="/terms" element={<RedirectToTerms />} />{" "}
          {/* Use the redirect component */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
