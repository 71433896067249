import React, { useEffect, useRef, useState } from "react";

const ClockTimer = ({ count, running, setCount, isMobile }) => {
  const timerRef = useRef(null);
  const [isPulsing, setIsPulsing] = useState(false);

  useEffect(() => {
    if (running) {
      timerRef.current = setInterval(() => {
        setCount((prevCount) => {
          const newCount = Math.max(prevCount - 1, 0);
          if (newCount === 10) {
            setIsPulsing(true); // Start pulsing at 10 seconds
          }
          return newCount;
        });
      }, 1000);
    }

    return () => clearInterval(timerRef.current);
  }, [running, setCount]);

  return (
    <div>
      <Clock time={count} isMobile={isMobile} isPulsing={isPulsing} />
    </div>
  );
};

export default ClockTimer;

function Clock({ time, isMobile, isPulsing }) {
  return (
    <div className={isMobile ? "timer-div-mobile" : "timer-div"}>
      <h1 className={`timer-display ${isPulsing ? "pulse-flash" : ""}`}>
        {time}s
      </h1>
    </div>
  );
}
