import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { doPasswordReset } from "../../firebase/auth";
import { mapErrorMessage } from "../../components/auth/login/errorMessages";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();
    setMessage(""); // Clear previous messages
    try {
      if (!isSubmitting) {
        setIsSubmitting(true);
        await doPasswordReset(email);
        setMessage("Password reset email sent. Redirecting to home page...");
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      setMessage(mapErrorMessage(err.code, err.message));
      setIsSubmitting(false);
    }
  };

  return (
    <main className="w-full h-screen flex self-center place-content-center place-items-center">
      <div className="w-96 text-gray-600 space-y-5 p-4 shadow-xl border rounded-xl">
        <div className="text-center mb-6">
          <div className="mt-2">
            <h3 className="text-gray-800 text-xl font-semibold sm:text-2xl">
              Reset Password
            </h3>
          </div>
        </div>
        <form onSubmit={onSubmit} className="space-y-4">
          <div>
            <label className="text-sm text-gray-600 font-bold">Email</label>
            <input
              type="email"
              autoComplete="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg transition duration-300"
            />
          </div>
          {message && (
            <span
              className={`text-sm font-bold ${
                message.includes("sent") ? "text-green-600" : "text-red-600"
              }`}
            >
              {message}
            </span>
          )}
          <button
            type="submit"
            disabled={isSubmitting}
            className={`w-full px-4 py-2 text-white font-medium rounded-lg ${
              isSubmitting
                ? "bg-gray-300 cursor-not-allowed"
                : "bg-indigo-600 hover:bg-indigo-700 hover:shadow-xl transition duration-300"
            }`}
          >
            {isSubmitting ? "Submitting..." : "Reset Password"}
          </button>
          <div className="text-sm text-center">
            Remembered your password?{" "}
            <Link
              to={"/login"}
              className="text-center text-sm hover:underline font-bold"
            >
              Login
            </Link>
          </div>
        </form>
      </div>
    </main>
  );
};

export default ForgotPassword;
