import React, { useState } from "react";
import { IoClose } from "react-icons/io5";

const SettingsItems = ({ setShowSettings }) => {
  const isDebugMode = process.env.REACT_APP_DEBUG === "true";

  const [selection, setSelection] = useState("");

  const infoSections = [
    {
      id: 1,
      title: "Questions & Feedback",
      linkText: "Email",
      href: "mailto:info@fragmentsofmemory.com",
    },
    {
      id: 2,
      title: "Report a Bug",
      linkText: "Email",
      href: "mailto:info@fragmentsofmemory.com",
    },
    {
      id: 3,
      title: "Submit new puzzles!",
      linkText: "Email",
      href: "mailto:info@fragmentsofmemory.com",
    },
  ];

  if (isDebugMode) {
    infoSections.push(
      {
        id: 4,
        title: "Clear Memory",
        linkText: "Clear",
        href: "#",
      },
      {
        id: 5,
        title: "Select Date",
        options: [1, 2, 3, 4],
      }
    );
  }

  const handleClearMemory = () => {
    localStorage.clear();
    alert("Memory Cleared");
    window.location.reload(); // Refresh the page
  };

  const handleDateSelection = (e) => {
    const value = e.target.value;
    setSelection(value);

    let targetDate;
    switch (value) {
      case "1":
        targetDate = "2024-07-24";
        break;
      case "2":
        targetDate = "2024-07-25";
        break;
      case "3":
        targetDate = "2024-07-26";
        break;
      case "4":
        targetDate = "2024-07-27";
        break;
      default:
        targetDate = "";
    }

    if (targetDate) {
      localStorage.clear();
      localStorage.setItem("targetDate", targetDate);
      window.location.reload(); // Refresh the page
    }
  };

  return (
    <div className="setting-div">
      <IoClose onClick={() => setShowSettings(false)} className="close-icon" />
      <p className="setting-text">SETTING</p>
      {infoSections.map((section) => (
        <div key={section.id} className="setting-section">
          <p>{section.title}</p>
          {section.title === "Clear Memory" ? (
            <button onClick={handleClearMemory} className="link-text">
              {section.linkText}
            </button>
          ) : section.title === "Select Date" ? (
            <select
              value={selection}
              onChange={handleDateSelection}
              className="link-text"
            >
              <option value="">Select an option</option>
              {section.options.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          ) : (
            <a href={section.href}>
              <p className="link-text">{section.linkText}</p>
            </a>
          )}
        </div>
      ))}
    </div>
  );
};

export default SettingsItems;
