// src/components/RedirectToPrivacy.js

import { useEffect } from "react";

const RedirectToTerms = () => {
  useEffect(() => {
    window.location.href =
      "https://app.termly.io/policy-viewer/policy.html?policyUUID=5e4a2903-b9fa-471d-8454-8674ec3df521";
  }, []);

  return null; // This component renders nothing
};

export default RedirectToTerms;
