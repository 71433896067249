import React from "react";
import { IoClose } from "react-icons/io5";
import choicesImage from "./../../media/choices.png";

const HowToPlayModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <IoClose
          onClick={onClose}
          className="modal-close"
          aria-label="Close modal"
        />

        <p className="modal-title">How To Play</p>
        <div className="modal-body">
          <div className="modal-components">
            <h2 className="modal-h2-title">
              Memorize the picture before the time runs out!
            </h2>
            <ul className="modal-ul-bullets">
              <li>You get 3 guesses for each question.</li>
              <li>
                The color of the answers will change to show whether you picked
                the right answer.
              </li>
            </ul>
            {/* Add the centered image here */}
            <div style={{ textAlign: "center", marginTop: "10px" }}>
              <img
                src={choicesImage}
                className="modal-image"
                alt="Answer Choices"
                style={{
                  height: "100px",
                  width: "auto",
                  display: "block",
                  margin: "0 auto",
                  marginBottom: "10px",
                }}
              />
            </div>

            <hr />
          </div>
          <div className="modal-components">
            <div
              style={{
                marginBottom: "15px",
              }}
            >
              <a href="/login" rel="noreferrer">
                Log in or create a free account
              </a>{" "}
              to link your stats.
            </div>
            <hr />
          </div>
          <div className="modal-components">
            <p>
              A <strong>new puzzle</strong> is released{" "}
              <strong>daily at midnight</strong>. If you haven’t already, you
              can{" "}
              <a
                href="https://fragmentsofmemory.beehiiv.com/subscribe"
                target="_blank"
                rel="noreferrer"
              >
                sign up
              </a>{" "}
              for our daily reminder email.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowToPlayModal;
