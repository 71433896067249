import { db, auth } from "../firebase/firebase";
import {
  getDocs,
  collection,
  addDoc,
  query,
  where,
  orderBy,
  limit,
} from "firebase/firestore";

import { Timestamp } from "@firebase/firestore";

function arrayToMap(arr) {
  return arr.reduce((acc, curr, index) => {
    acc[index] = curr;
    return acc;
  }, {});
}

function mapToArray(map) {
  return Object.keys(map)
    .sort((a, b) => a - b)
    .map((key) => map[key]);
}

const userEntriesCollectionRef = collection(db, "userEntries");

export const createNewUserEntry = async (userEntry) => {
  try {
    userEntry["userId"] = auth?.currentUser?.uid ?? "";
    userEntry["gameState"] = arrayToMap(userEntry["gameState"]);
    userEntry["created_at"] = Timestamp.fromDate(new Date());

    await addDoc(userEntriesCollectionRef, userEntry);
  } catch (err) {
    console.error(err);
  }
};

export const getLatestDocumentByCurrentUser = async () => {
  const currentUser = auth?.currentUser;

  if (!currentUser) {
    console.log("No user is currently logged in.");
    return null;
  }

  const userId = currentUser.uid;

  const q = query(
    collection(db, "userEntries"),
    where("userId", "==", userId),
    orderBy("created_at", "desc"),
    limit(1)
  );

  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    console.log("No documents found for the current user");
    return null;
  }

  const latestDocument = querySnapshot.docs[0];
  const latestData = { id: latestDocument.id, ...latestDocument.data() };

  // Check if gameState exists and is a map, then convert it to an array
  if (latestData.gameState && typeof latestData.gameState === "object") {
    latestData.gameState = mapToArray(latestData.gameState);
  }

  return latestData;
};
