import React, { useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";
import pinchImage from "./../../media/pinch.svg"; // Updated path for the pinch image
import { useAuth } from "../../contexts/authContext";

const ExclamationModal = ({ isOpen, onClose }) => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);

  const { userLoggedIn } = useAuth();

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (!isOpen) return null;

  return (
    <div>
      <div className="modal-overlay">
        <div className="modal-content">
          <IoClose
            onClick={onClose}
            className="modal-close"
            aria-label="Close modal"
          />

          <p className="modal-title">Hurry up!</p>
          <p className="modal-body">
            Memorize the picture before the timer runs out! Or hit skip whenever
            you are ready!
          </p>
          {isDesktop ? (
            <p className="modal-body">
              Use scroll wheel to zoom in and mouse to pan 🖱️
            </p>
          ) : (
            <div style={{ textAlign: "center" }}>
              <img
                src={pinchImage}
                alt="Pinch gesture"
                style={{
                  width: "90px",
                  height: "90px",
                  display: "block",
                  margin: "0 auto",
                }}
              />
              <p className="modal-body">
                <strong>Use pinch gesture to zoom in</strong>
              </p>
            </div>
          )}

          {/* Conditional rendering based on userLoggedIn */}
          {!userLoggedIn && (
            <div className="exclamation-login">
              <hr />
              <div className="exclamation-login">
                <a href="/login" rel="noreferrer">
                  Log in or create a free account
                </a>{" "}
                to link your stats.
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExclamationModal;
