/* eslint-disable no-unused-vars */
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getAnalytics } from "firebase/analytics";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCoG7ICWgY1fT_b2ysJkdCx787zncr3YHU",
  authDomain: "reactmemorygame-f91e5.firebaseapp.com",
  projectId: "reactmemorygame-f91e5",
  storageBucket: "reactmemorygame-f91e5.appspot.com",
  messagingSenderId: "608524958113",
  appId: "1:608524958113:web:e18dbf5c8599fadb84b3e0",
  measurementId: "G-06TVDPSR0E",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const storage = getStorage(app);
// const functions = getFunctions(app);

// if(window.location.hostname.includes("localhost"))
// {
//   connectFunctionsEmulator(functions,"127.0.0.1",5001)
//   connectFirestoreEmulator(db,"127.0.0.1",8080)
// }

export { app, auth, analytics, db, storage };
